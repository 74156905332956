.navbar {
  background-color: #020202;
  padding: 0;
  width: 100%;
  position: relative;
  z-index: 1000;
  height: 50px;
  display: flex;
  align-items: center;
}

.nav-container {
  width: 100%;
  padding: 15px 20px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.nav-logo {
  display: flex;
  align-items: center;
  gap: 1px;
  color: #2ecc71;
  text-decoration: none;
  font-size: 24px;
  font-weight: 600;
  margin: 0;
  padding: 0;
}

.logo-image {
  height: 50px;
  width: auto;
  margin: 0;
  object-fit: contain;
  background: transparent;
  filter: brightness(0) saturate(100%) invert(72%) sepia(40%) saturate(651%) hue-rotate(93deg) brightness(91%) contrast(87%);
}

.nav-links {
  display: flex;
  align-items: center;
  gap: 30px;
  margin-left: 40px;
}

.nav-links a {
  color: #666;
  text-decoration: none;
  font-size: 14px;
  transition: color 0.2s;
  white-space: nowrap;
}

.nav-links a:hover {
  color: #2ecc71;
}

/* 汉堡菜单按钮 */
.menu-toggle {
  display: none;
  background: none;
  border: none;
  padding: 10px;
  cursor: pointer;
  margin: 0;
}

.hamburger {
  display: block;
  width: 24px;
  height: 2px;
  background: #ffffff;
  position: relative;
  transition: all 0.3s ease;
}

.hamburger::before,
.hamburger::after {
  content: '';
  position: absolute;
  width: 24px;
  height: 2px;
  background: #ffffff;
  transition: all 0.3s ease;
}

.hamburger::before {
  top: -6px;
}

.hamburger::after {
  bottom: -6px;
}

/* 搜索框样式 */
.nav-search {
  width: 100%;
  max-width: 300px;
  margin: 10px 0;
}

.nav-search-input {
  width: 100%;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
  color: #333;
  background: white;
}

/* 移动端样式 */
@media (max-width: 768px) {
  .menu-toggle {
    display: block;
    position: relative;
    right: 0;
  }

  .nav-container {
    justify-content: space-between;
    padding: 0 20px;
  }

  .nav-links {
    display: none;
    position: absolute;
    top: 60px;
    left: 0;
    right: 0;
    background: #020202;
    flex-direction: column;
    padding: 20px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  }

  .nav-links.active {
    display: flex;
  }

  .nav-links a {
    padding: 10px 0;
    width: 100%;
    text-align: center;
    color: #ffffff;
  }

  /* 当菜单打开时汉堡按钮动画 */
  .menu-toggle.active .hamburger {
    background: transparent;
  }

  .menu-toggle.active .hamburger::before {
    transform: rotate(45deg);
    top: 0;
  }

  .menu-toggle.active .hamburger::after {
    transform: rotate(-45deg);
    bottom: 0;
  }

  .nav-logo {
    left: 0;
  }
}

/* 电脑端样式 */
@media (min-width: 769px) {
  .nav-links {
    display: flex !important;
    position: static;
    background: none;
    box-shadow: none;
    padding: 0;
  }

  .menu-toggle {
    display: none;
  }
}

/* 修改 Top Download 的间距 */
.nav-links a[href="/top-downloads"] {
  white-space: nowrap;
  display: inline-block;
}