.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* 导航栏的背景 */
.navbar {
  /* background-color: #333; */
  background-color: #020202;
  padding: 0.17rem;  /* 固定导航栏高度 */
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.nav-brand a {
  color: white;
  /* color: rgb(27, 26, 26); */
  text-decoration: none;
  font-size: 1.5rem;
}

.nav-links a {
  color: white;
  text-decoration: none;
  margin-left: 1rem;
}

.search-section {
  padding: 3rem 1rem;
}

.search-input {
  width: 80%;
  max-width: 600px;
  padding: 12px 20px;
  margin: 8px 0;
  border: 2px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
}

.app {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: #f8f9fa;
}


.main-content {
  flex: 1;
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
  padding: 0 15px;
  padding-top: 60px; /* 根据 Navbar 高度调整 */
}

/* 重置一些基础样式 */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
    Ubuntu, Cantarell, 'Helvetica Neue', sans-serif;
  line-height: 1.5;
  color: #333;
  /* color: #eb0d0d; */
  background-color: #f8f9fa;
}

/* 通用容器类 */
.container {
  width: 100%;
  max-width: 1400px;
  margin: 0 auto;
  padding: 0 20px;
}

/* 响应式布局 */
@media (max-width: 1440px) {
  .container {
    max-width: 1200px;
  }
}

@media (max-width: 1200px) {
  .container {
    max-width: 1000px;
  }
}

/* 响应式设计 */
@media (max-width: 768px) {
  .main-content {
    padding-top: 56px; /* 移动端导航栏可能更小 */
  }
}