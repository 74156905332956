.privacy-page {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
}

.privacy-container {
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 2rem;
}

.privacy-section {
  margin-bottom: 2.5rem;
}

.privacy-section h1 {
  font-size: 2.5rem;
  color: #333;
  margin-bottom: 1.5rem;
}

.privacy-section h2 {
  font-size: 1.8rem;
  color: #444;
  margin-bottom: 1rem;
}

.privacy-section h3 {
  font-size: 1.4rem;
  color: #555;
  margin: 1rem 0;
}

.privacy-section ul {
  list-style-type: disc;
  margin-left: 1.5rem;
  margin-bottom: 1.5rem;
}

.privacy-section li {
  margin-bottom: 0.5rem;
  line-height: 1.6;
  color: #666;
}

.privacy-page h1 {
  text-align: center;
  font-size: 32px;
  font-weight: 700;
  color: #1a1a1a;
  margin-bottom: 40px;
  padding-bottom: 20px;
  border-bottom: 2px solid #f0f0f0;
} 