.top-downloads-page {
  padding: 20px 0;
  background-color: #f8f9fa;
}

.top-downloads-container {
  max-width: 1280px;
  margin: 0 auto;
  padding: 0 15px;
}

/* .page-header {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 8px;
  margin-bottom: 20px;
  box-shadow: 0 1px 2px rgba(0,0,0,0.05);
} */

/* .page-header h1 {
  font-size: 24px;
  color: #333;
  margin: 0 0 15px 0;
} */

.filter-tabs {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
}

.filter-tab {
  padding: 8px 16px;
  border: none;
  border-radius: 6px;
  background: white;
  color: #666;
  cursor: pointer;
  font-size: 14px;
  transition: all 0.2s;
}

.filter-tab.active {
  background: #007bff;
  color: white;
}

.filter-tab:hover:not(.active) {
  background: #f0f0f0;
}

.apps-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  gap: 12px;
}

.app-card {
  background: #fff;
  padding: 10px;
  display: flex;
  align-items: center;
  gap: 10px;
  text-decoration: none;
  color: inherit;
  transition: background-color 0.2s;
  border-radius: 8px;
}

.app-card:hover {
  background-color: #f8f9fa;
}

.app-icon {
  width: 40px;
  height: 40px;
  border-radius: 8px;
  object-fit: cover;
}

.app-info {
  flex: 1;
  min-width: 0;
  padding-right: 5px;
}

.app-name {
  font-size: 14px;
  font-weight: 500;
  color: #333;
  margin: 0 0 3px 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.app-meta {
  display: flex;
  align-items: center;
  gap: 12px;
  font-size: 12px;
  color: #666;
}

.app-downloads {
  color: #666;
  white-space: nowrap;
}

.app-rating {
  color: #666;
  white-space: nowrap;
}

@media (max-width: 768px) {
  .apps-grid {
    grid-template-columns: 1fr;
  }
  
  .filter-tabs {
    overflow-x: auto;
    padding-bottom: 5px;
  }
  
  .filter-tab {
    white-space: nowrap;
  }
}

.filter-container {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-top: 20px;
}

.category-filter {
  margin-right: 20px;
}

.category-filter select {
  padding: 8px 16px;
  border-radius: 6px;
  border: 1px solid #ddd;
  font-size: 14px;
  background-color: white;
  min-width: 150px;
}

@media (min-width: 768px) {
  .filter-container {
    flex-direction: row;
    align-items: center;
  }
}

.page-title {
  font-size: 24px;
  color: #333;
  text-align: left;
  margin: 20px 0;
  font-weight: 500;
  padding: 0 15px;
} 