.category-page {
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
  overflow-x: hidden;
}

.category-page h1 {
  text-align: center;
  margin-bottom: 30px;
  color: #333;
}

.apps-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 12px;
  padding: 0 16px;
  width: 100%;
  box-sizing: border-box;
}

@media (max-width: 768px) {
  .category-page {
    padding: 10px 0;
  }
  
  .apps-grid {
    padding: 0 12px;
  }
  
  .category-page h1 {
    font-size: 24px;
    margin-bottom: 20px;
  }
}

.loading {
  text-align: center;
  padding: 40px;
  font-size: 18px;
} 