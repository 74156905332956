.footer {
  background-color: #fff;
  padding: 20px 0;
  margin-top: auto;
  border-top: 1px solid #eee;
  font-size: 13px;
}

.footer-container {
  max-width: 1280px;
  margin: 0 auto;
  padding: 0 15px;
}

.footer-content {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.footer-description {
  color: #666;
  margin: 0;
}

.footer-links {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 15px;
}

.footer-label {
  color: #333;
  font-weight: 500;
}

.footer-links a {
  color: #666;
  text-decoration: none;
  transition: color 0.2s;
}

.footer-links a:hover {
  color: #007bff;
}

/* 在链接之间添加分隔符 */
.footer-links a:not(:last-child)::after {
  content: '•';
  margin-left: 15px;
  color: #ccc;
}

@media (max-width: 768px) {
  .footer {
    padding: 15px 0;
  }

  .footer-content {
    gap: 10px;
  }

  .footer-links {
    gap: 10px;
  }

  /* 在移动端移除分隔符 */
  .footer-links a:not(:last-child)::after {
    display: none;
  }
} 