.home {
  width: 100%;
}

.search-hero {
  width: 100%;
  background: linear-gradient(135deg, #0061ff 0%, #60efff 100%);
  padding: 80px 0;
}

.search-container {
  width: 100%;
  max-width: 1400px;
  margin: 0 auto;
  padding: 0 20px;
}

.content-container {
  max-width: 1920px;
  margin: 0 auto;
  padding: 20px 15px;
}

.section {
  background: white;
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 20px;
  box-shadow: 0 1px 3px rgba(0,0,0,0.05);
}

.section-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 25px;
}

.section-header h2 {
  font-size: 24px;
  color: #1a1a1a;
  margin: 0;
  font-weight: 600;
}

.view-all {
  color: #007bff;
  text-decoration: none;
  font-size: 14px;
  font-weight: 500;
  padding: 6px 12px;
  border-radius: 6px;
  transition: background-color 0.2s;
}

.view-all:hover {
  background-color: #f0f7ff;
}

.section-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  gap: 20px;
}

.app-description {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
  color: #666;
  margin: 8px 0;
  line-height: 1.4;
}

.app-card {
  display: flex;
  padding: 16px;
  background: white;
  text-decoration: none;
  color: inherit;
  align-items: center;
  gap: 16px;
  border-bottom: 1px solid #f0f0f0;
  width: 100%;
}

.app-icon {
  width: 56px;
  height: 56px;
  border-radius: 12px;
  object-fit: cover;
  flex-shrink: 0;
}

.app-info {
  flex: 1;
  min-width: 0;
  display: flex;
  flex-direction: column;
  gap: 4px;
  max-width: calc(100% - 100px);
}

.app-name {
  font-size: 16px;
  font-weight: 500;
  color: #333;
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.app-description {
  font-size: 13px;
  color: #666;
  margin: 0;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
}

.app-card::after {
  content: "›";
  color: #ccc;
  font-size: 24px;
  flex-shrink: 0;
  width: 24px;
  text-align: center;
}

@media (max-width: 768px) {
  .app-card {
    padding: 12px;
  }
  
  .app-icon {
    width: 48px;
    height: 48px;
  }
  
  .app-info {
    max-width: calc(100% - 84px);
  }
} 