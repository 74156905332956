.terms-page {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
}

.terms-container {
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 2rem;
}

.terms-section {
  margin-bottom: 2.5rem;
}

.terms-section h1 {
  font-size: 2.5rem;
  color: #333;
  margin-bottom: 1.5rem;
}

.terms-section h2 {
  font-size: 1.8rem;
  color: #444;
  margin-bottom: 1rem;
}

.terms-subsection {
  margin-bottom: 1.5rem;
}

.terms-subsection h3 {
  font-size: 1.4rem;
  color: #555;
  margin-bottom: 0.8rem;
}

.terms-section p {
  font-size: 1.1rem;
  line-height: 1.6;
  color: #666;
  margin-bottom: 1rem;
}

.terms-footer {
  margin-top: 3rem;
  padding-top: 1rem;
  border-top: 1px solid #eee;
  color: #888;
  font-size: 0.9rem;
}

.terms-title {
  text-align: center;
  font-size: 32px;
  font-weight: 700;
  color: #1a1a1a;
  margin-bottom: 40px;
  padding-bottom: 20px;
  border-bottom: 2px solid #f0f0f0;
} 