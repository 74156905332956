.home {
  width: 100%;
  background-color: #f8f9fa;
}

.content-container {
  max-width: 1920px;
  margin: 0 auto;
  padding: 40px 15px;
}

.section-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1px;
  margin-top: 0;/* 添加这行确保顶部没有额外间距 */
}

.section-header h2 {
  font-size: 24px;
  color: #1a1a1a;
  margin: 0;
  font-weight: 600;
  padding: 0;/* 添加这行确保没有额外的内边距 */
}

.view-all {
  color: #007bff;
  text-decoration: none;
  font-size: 14px;
  font-weight: 500;
  padding: 6px 12px;
  border-radius: 6px;
  transition: background-color 0.2s;
}

.view-all:hover {
  background-color: #f0f7ff;
}

.section-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  gap: 20px;
} 


/* App Categories 部分 */
.app-categories {
  padding: 40px 20px;
  background: #f8f9fa;
}

.app-categories h2 {
  text-align: center;
  margin-bottom: 32px;
  color: #333;
  font-size: 24px;
}

.categories-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 16px;
  max-width: 1200px;
  margin: 0 auto;
}

.category-card {
  background: white;
  border-radius: 12px;
  padding: 16px;
  text-align: center;
  text-decoration: none;
  color: #333;
  transition: all 0.3s ease;
  box-shadow: 0 2px 4px rgba(0,0,0,0.05);
}

.category-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
}

.category-icon {
  font-size: 32px;
  margin-bottom: 8px;
}

.category-name {
  font-size: 14px;
  font-weight: 500;
  color: #444;
  /* 处理长文本 */
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/* 响应式设计 */
@media (max-width: 768px) {
  .categories-grid {
    grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
  }
  
  .category-icon {
    font-size: 28px;
  }
  
  .category-name {
    font-size: 12px;
  }
}

/* 添加 app-card 相关样式 */
.app-card {
  display: flex;
  padding: 16px;
  background: white;
  text-decoration: none;
  color: inherit;
  align-items: center;
  gap: 16px;
  border-bottom: 1px solid #f0f0f0;
  width: 100%;
}

.app-icon {
  width: 56px;
  height: 56px;
  border-radius: 12px;
  object-fit: cover;
  flex-shrink: 0;
}

.app-info {
  flex: 1;
  min-width: 0;
  display: flex;
  flex-direction: column;
  gap: 4px;
  max-width: calc(100% - 100px);
}

.app-name {
  font-size: 16px;
  font-weight: 500;
  color: #333;
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.app-description {
  font-size: 13px;
  color: #666;
  margin: 0;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
}

.app-card::after {
  content: "›";
  color: #ccc;
  font-size: 24px;
  flex-shrink: 0;
  width: 24px;
  text-align: center;
}

@media (max-width: 768px) {
  .app-card {
    padding: 12px;
  }
  
  .app-icon {
    width: 48px;
    height: 48px;
  }
  
  .app-info {
    max-width: calc(100% - 84px);
  }
}

/* Articles Section */
.articles-section {
  padding: 40px 0;
}

.articles-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 24px;
  margin-top: 20px;
}

.article-card {
  background: white;
  border-radius: 12px;
  overflow: hidden;
  text-decoration: none;
  color: inherit;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  box-shadow: 0 2px 4px rgba(0,0,0,0.05);
}

.article-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
}

.article-image {
  width: 100%;
  height: 180px;
  overflow: hidden;
}

.article-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.article-info {
  padding: 16px;
}

.article-info h3 {
  margin: 0 0 8px 0;
  font-size: 18px;
  font-weight: 600;
  color: #333;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.article-info time {
  font-size: 14px;
  color: #666;
}

@media (max-width: 768px) {
  .articles-grid {
    grid-template-columns: 1fr;
  }
  
  .article-image {
    height: 160px;
  }
  
  .article-info h3 {
    font-size: 16px;
  }
}

/* 添加移动端适配 */
@media (max-width: 768px) {
  .content-container {
    padding: 20px 0; /* 移除左右padding */
  }

  .section-header {
    padding: 0 15px; /* 为标题保留一些边距 */
  }

  .apps-list {
    margin: 0; /* 移除可能存在的外边距 */
  }

  /* 确保应用列表贴边 */
  .app-card {
    border-radius: 0; /* 移除圆角 */
    margin: 0; /* 移除外边距 */
    border-left: none; /* 移除左边框 */
    border-right: none; /* 移除右边框 */
  }
}