.editors-choice-page {
  padding: 20px 0;
  background-color: #f8f9fa;
}

.editors-choice-container {
  max-width: 1280px;
  margin: 0 auto;
  padding: 0 15px;
}

.page-title {
  font-size: 24px;
  color: #333;
  text-align: left;
  margin: 20px 0;
  font-weight: 500;
  padding: 0 15px;
}

.apps-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  gap: 12px;
}

@media (max-width: 768px) {
  .apps-grid {
    grid-template-columns: 1fr;
  }
} 