.article-detail-page {
  padding: 40px 20px;
  background-color: #f8f9fa;
  min-height: 100vh;
}

.article-detail-container {
  max-width: 800px;
  margin: 0 auto;
  background: #fff;
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0,0,0,0.05);
  padding: 40px;
}

.article-content {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, sans-serif;
}

.article-title {
  font-size: 32px;
  font-weight: 700;
  color: #333;
  margin-bottom: 16px;
  line-height: 1.3;
}

.article-meta {
  margin-bottom: 32px;
  color: #666;
  font-size: 14px;
}

.article-body {
  font-size: 16px;
  line-height: 1.8;
  color: #444;
}

.article-paragraph {
  margin-bottom: 24px;
}

.article-body h2 {
  font-size: 24px;
  font-weight: 600;
  color: #333;
  margin: 40px 0 20px;
}

.article-body h3 {
  font-size: 20px;
  font-weight: 600;
  color: #333;
  margin: 32px 0 16px;
}

.article-bold {
  font-weight: 600;
  color: #333;
}

.article-body ul, 
.article-body ol {
  margin: 20px 0;
  padding-left: 24px;
}

.article-body li {
  margin-bottom: 12px;
}

@media (max-width: 768px) {
  .article-detail-container {
    padding: 24px;
  }
  
  .article-title {
    font-size: 28px;
  }
}

.article-not-found {
  text-align: center;
  padding: 100px 20px;
  font-size: 24px;
  color: #666;
} 