.app-detail {
  max-width: 100%;
  margin: 0 auto;
  padding: 10px;
}

.description-section {
  margin: 20px 0;
  padding: 20px;
}

.description-section h2 {
  margin-bottom: 20px;
  font-size: 20px;
  color: #333;
}

.description-text {
  white-space: pre-wrap;
  word-break: break-word;
  line-height: 1.8;
  font-size: 15px;
  color: #333;
  font-family: inherit;
  margin: 0;
  padding: 0;
  border: none;
  background: none;
  width: 100%;
  display: block;
}

.info-section {
  margin-top: 20px;
  padding: 20px;
  border-top: 1px solid #eee;
}

.info-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 20px;
}

.app-detail-header {
  padding: 20px;
  background: #fff;
}

.app-basic-info {
  display: flex;
  align-items: flex-start;
  gap: 20px;
}

.app-icon {
  width: 80px;
  height: 80px;
  border-radius: 12px;
  object-fit: cover;
}

.app-header-info {
  flex: 1;
}

.app-header-info h1 {
  font-size: 24px;
  margin: 0 0 4px 0;
  color: #333;
}

.developer {
  font-size: 14px;
  color: #666;
  margin: 0 0 8px 0;
}

.rating-info {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 8px;
}

.rating {
  font-size: 14px;
  color: #333;
}

.rating-count {
  font-size: 14px;
  color: #666;
}

.category {
  font-size: 14px;
  color: #666;
  margin: 0;
}

.info-grid-section {
  padding: 20px;
  border-bottom: 1px solid #f0f0f0;
}

.info-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);  /* 三列 */
  grid-template-rows: repeat(2, auto);    /* 两行 */
  gap: 20px;
}

.info-item {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.info-item h3 {
  font-size: 13px;
  color: #666;
  margin: 0;
  font-weight: normal;
}

.info-item p {
  font-size: 15px;
  color: #333;
  margin: 0;
}

.download-buttons {
  display: flex;
  gap: 12px;
  padding: 20px;
  margin-bottom: 20px;
}

.download-button {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 12px 24px;
  border-radius: 8px;
  font-weight: 500;
  font-size: 15px;
  transition: all 0.2s ease;
  border: none;
  cursor: pointer;
  color: white;
}

.button-icon {
  width: 20px;
  height: 20px;
  object-fit: contain;
}

/* 特定按钮样式 */
.apk-button {
  background-color: #0066ff;
}

.play-button {
  background-color: #0066ff;
}

.appstore-button {
  background-color: #0066ff;
}

/* 移动端适配 */
@media (max-width: 768px) {
  .download-buttons {
    flex-direction: column;
    padding: 16px;
    gap: 8px;
  }

  .download-button {
    width: 100%;
    padding: 1x;
    font-size: 16px;
  }

  .button-icon {
    width: 22px;
    height: 22px;
  }

  .app-detail {
    padding: 1px;
  }
}

.similar-apps {
  margin: 32px 0;
  padding: 0 16px;
}

.similar-apps h2 {
  font-size: 20px;
  font-weight: 500;
  color: #262626;
  margin-bottom: 16px;
}

.similar-apps-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 16px;
  margin-top: 16px;
}

.similar-app-card {
  display: flex;
  align-items: center;
  padding: 12px;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  text-decoration: none;
  color: inherit;
  transition: transform 0.2s ease;
}

.similar-app-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
}

.similar-app-logo-container {
  width: 48px;
  height: 48px;
  margin-right: 12px;
  flex-shrink: 0;
}

.similar-app-logo {
  width: 100%;
  height: 100%;
  border-radius: 8px;
  object-fit: cover;
}

.similar-app-content {
  flex: 1;
  min-width: 0;
}

.similar-app-content h3 {
  margin: 0;
  font-size: 14px;
  font-weight: 500;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.similar-app-meta {
  margin-top: 4px;
}

.similar-app-meta .developer {
  margin: 0;
  font-size: 12px;
  color: #666;
}

.similar-app-meta .rating-info {
  font-size: 12px;
  color: #666;
  margin-top: 2px;
}

.screenshots-section {
  margin: 24px 0;
  padding: 0 16px;
}

.screenshots-scroll {
  display: flex;
  overflow-x: auto;
  gap: 12px;
  padding: 8px 0;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.screenshots-scroll::-webkit-scrollbar {
  display: none;
}

.screenshot-item {
  flex: 0 0 auto;
  width: 180px;
  height: 320px;
  border-radius: 12px;
  overflow: hidden;
  background: #f5f5f5;
}

.screenshot-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.screenshots-container {
  position: relative;
  padding: 0 40px; /* 为按钮留出空间 */
}

.scroll-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.8);  /* 半透明白色背景 */
  border: none;  /* 移除边框 */
  cursor: pointer;
  font-size: 32px;
  color: #666;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);  /* 柔和的阴影 */
  backdrop-filter: blur(4px);  /* 背景模糊效果 */
  transition: all 0.2s ease;
}

.scroll-button:hover {
  background: rgba(255, 255, 255, 0.9);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
}

.scroll-left {
  left: 8px;  /* 稍微靠近内容 */
}

.scroll-right {
  right: 0;
}

/* 修改现有的 screenshots-scroll 样式 */
.screenshots-scroll {
  display: flex;
  overflow-x: auto;
  gap: 12px;
  padding: 8px 0;
  scrollbar-width: none;
  -ms-overflow-style: none;
  scroll-behavior: smooth; /* 添加平滑滚动 */
}

/* 只隐藏下载按钮，保留其他样式 */
.download-app {
  display: none;
}