.search-hero {
  background-size: cover;
  background-position: center;
  padding: 40px 0;
  color: white;
  position: relative;
  min-height: 400px;
  margin: 0;
  margin-top: -60px;
  background-image: linear-gradient(to right, 
    rgba(255, 193, 7, 0.95) 0%, 
    rgba(255, 193, 7, 0.8) 40%, 
    rgba(255, 193, 7, 0) 100%
  ), var(--bg-image);
  width: 100%;
}

.hero-content {
  width: 100%;
  padding: 0 15px;
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.app-showcase {
  display: flex;
  align-items: center;
  gap: 20px;
  flex: 1;
  max-width: 500px;
}

.app-hero-icon {
  width: 100px;
  height: 100px;
  border-radius: 20px;
  box-shadow: 0 4px 12px rgba(0,0,0,0.15);
}

.app-hero-info {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.app-hero-info h1 {
  font-size: 2.5rem;
  margin: 0;
  font-weight: 600;
}

.app-hero-info p {
  font-size: 1rem;
  margin: 0;
  opacity: 0.9;
}

.download-button {
  background: #007bff;
  color: white;
  border: none;
  padding: 12px 30px;
  border-radius: 8px;
  font-size: 1.1rem;
  font-weight: 500;
  cursor: pointer;
  transition: background 0.3s;
  width: fit-content;
}

.download-button:hover {
  background: #0056b3;
}

.search-container {
  flex: 1;
  max-width: 600px;
  text-align: center;
}

.search-box {
  display: flex;
  max-width: 600px;
  margin: 0 auto;
  background: white;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: none;
  border: none;
  height: 45px;
}

.search-input {
  flex: 1;
  padding: 0 20px;
  border: none;
  font-size: 16px;
  outline: none;
  box-shadow: none !important;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: white;
  border-radius: 8px 0 0 8px;
  border: 0;
  border-color: transparent;
  height: 100%;
  display: flex;
  align-items: center;
  color: #666;
}

.search-input:focus,
.search-input:active,
.search-input:hover {
  outline: none;
  border: none;
  box-shadow: none;
  -webkit-appearance: none;
}

.search-input::placeholder {
  color: #999;
  line-height: 45px;
  vertical-align: middle;
}

.search-button {
  padding: 0 30px;
  background: #007bff;
  color: white;
  border: none;
  font-size: 16px;
  cursor: pointer;
  transition: background 0.3s;
  box-shadow: none;
  border-radius: 0 8px 8px 0;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.popular-tags {
  margin-top: 20px;
  font-size: 14px;
}

.popular-tags span {
  opacity: 0.8;
  margin-right: 10px;
}

.popular-tags a {
  color: white;
  text-decoration: none;
  margin: 0 8px;
  opacity: 0.8;
  transition: opacity 0.3s;
}

.popular-tags a:hover {
  opacity: 1;
}

@media (max-width: 968px) {
  .hero-content {
    flex-direction: column;
    gap: 30px;
  }
  
  .app-showcase {
    text-align: center;
    flex-direction: column;
  }
  
  .app-hero-info {
    align-items: center;
  }
}

/* 移动端适配 */
@media (max-width: 768px) {
  .search-hero {
    padding: 20px 0;
    min-height: 300px;
    margin-top: -55px;
  }

  .hero-content {
    padding: 0 10px;
  }

  .search-box {
    width: 90%;
    margin: 0 auto;
  }

  .search-input {
    padding: 0 15px;
    font-size: 14px;
  }

  .search-button {
    padding: 0 20px;
    font-size: 14px;
  }

  .popular-tags {
    margin-top: 15px;
    font-size: 12px;
  }

  .popular-tags a {
    margin: 0 5px;
  }
}

/* 更小屏幕的适配 */
@media (max-width: 480px) {
  .search-hero {
    padding: 15px 0;
    min-height: 200px;
    margin-top: -55px;
    
  }

  .hero-content {
    
    padding: 0 8px;
  }

  .search-box {
    width: 100%;
    margin: 0 auto;
  }

  .search-input {
    padding: 0 15px;
    font-size: 14px;
  }

  .search-button {
    padding: 0 20px;
    font-size: 14px;
  }

  .popular-tags {
    margin-top: 15px;
    font-size: 12px;
  }

  .popular-tags a {
    margin: 0 5px;
  }
/* 更大屏幕的适配 */
  @media (max-width: 375px) {
    .search-hero {
      padding: 20px 0;
      min-height: 300px;
      margin-top: -55px;
    }
  
    .hero-content {
      padding: 0 10px;
    }
  
    .search-box {
      width: 90%;
      margin: 0 auto;
    }
  
    .search-input {
      padding: 0 15px;
      font-size: 14px;
    }
  
    .search-button {
      padding: 0 20px;
      font-size: 14px;
    }
  
    .popular-tags {
      margin-top: 15px;
      font-size: 12px;
    }
  
    .popular-tags a {
      margin: 0 5px;
    }
  }
  
} 