.search-results-page {
  background-color: #f5f7fa;
  min-height: 100vh;
  padding: 40px 20px;
}

.search-results-container {
  max-width: 1200px;
  margin: 0 auto;
}

.search-header {
  margin-bottom: 30px;
}

.search-header h2 {
  font-size: 24px;
  color: #1a1a1a;
  margin: 0 0 10px 0;
}

.search-header p {
  color: #666;
  margin: 0;
}

.results-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
}

.loading-spinner {
  text-align: center;
  padding: 40px;
  color: #666;
}

.no-results {
  text-align: center;
  padding: 60px 20px;
  background: white;
  border-radius: 12px;
  grid-column: 1 / -1;
}

.no-results h3 {
  margin: 0 0 10px 0;
  color: #1a1a1a;
}

.no-results p {
  color: #666;
  margin: 0;
} 